import moment from 'moment';
import { privateAPI } from './index';

export const companyHolidaysAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getCompanyHolidays: build.query({
      query: params => ({
        url: 'hr/company-holidays/',
        method: 'GET',
        params,
      }),
      providesTags: ['holidaysList']
    }),
    getHoliday: build.query({
      query: id => ({
        url: `hr/company-holidays/${id}/`,
        method: 'GET',
      }),
      providesTags: ['holidayDetail']
    }),
    addHoliday: build.mutation({
      query: values => {
        const holiday = {
          title: values.title,
          description: values.description,
          from_date: values.from_date
            ? moment(values.from_date).format('YYYY-MM-DD')
            : '',
          to_date: values.to_date ? moment(values.to_date).format('YYYY-MM-DD') : '',
          company: values.company
        };
        return {
          url: 'hr/company-holidays/',
          method: 'POST',
          body: holiday
        };
      },
      invalidatesTags: ['holidaysList']
    }),
    editHoliday: build.mutation({
      query: values => {
        const holiday = {
          title: values.title,
          company: values.company,
          description: values.description,
          from_date: values.from_date
            ? moment(values.from_date).format('YYYY-MM-DD')
            : '',
          to_date: values.to_date ? moment(values.to_date).format('YYYY-MM-DD') : '',
        };

        return {
          url: `hr/company-holidays/${values.id}/`,
          method: 'PUT',
          body: holiday
        };
      },
      invalidatesTags: ['holidaysList', 'holidayDetail']
    }),
    deleteHoliday: build.mutation({
      query: id => ({
        url: `hr/company-holidays/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['holidaysList']
    }),
    getCompanyHolidayDates: build.query({
      query: params => ({
        url: 'LeaveDetail/total_company_holidays_for_calendar/',
        params,
        method: 'GET',
      }),
    }),
    getCompanyProfiles: build.query({
      query: params => ({
        url: '/CompanyProfile/',
        method: 'GET',
        params,
      }),
    }),
    getDetailHoliday: build.query({
      query: id => ({
        url: `hr/company-holidays/${id}/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetCompanyHolidaysQuery,
  useGetCompanyHolidaysQuery,
  useGetCompanyProfilesQuery,
  useDeleteHolidayMutation,
  useGetCompanyHolidayDatesQuery,
  useGetHolidayQuery,
  useAddHolidayMutation,
  useEditHolidayMutation,
  useGetDetailHolidayQuery
} = companyHolidaysAPI;
